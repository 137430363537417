<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile/RestingHeartRate'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.manually')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item>
        <ion-label position="floating">{{ __('interface.resting-heart-rate') }}</ion-label>
        <ion-input v-model="form.resting_heart_rate"/>
      </ion-item>
      <ion-button :disabled="form.resting_heart_rate <= 0" expand="full" class="ion-margin-vertical" @click="actionSave">{{
          __('interface.save')
        }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, loadingController, toastController} from "@ionic/vue";
import {API} from "@/utils/apiWrapper";

export default {
  name: "Manually",
  components: {IonPage},
  data() {
    return {
      form: {
        resting_heart_rate: 0
      }
    }
  },
  methods: {
    async actionSave() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.post('TheSpottApi', '/me-rhr', {
        body: this.form
      }).then(async response => {
        this.$store.commit('setUserRhr', response.resting_heart_rate)
        await loading.dismiss();
        await this.$router.push({name: 'MyProfile/RestingHeartRate'})
        const toast = await toastController.create({
          message: this.__('message.saved-successfully'),
          duration: 2000
        })
        await toast.present();
        this.$tracking.event({
          eventName: 'record-manually',
          category: 'rhr'
        });
      }).catch(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.something-went-wrong'),
          duration: 2000
        })
        await toast.present();
      })
    }
  },
  ionViewWillEnter() {
    this.form.resting_heart_rate = 0
  }
}
</script>